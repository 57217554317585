import React, { useEffect, useState } from "react";
import "../styles/Login.css";
import {
  chakkraLogo,
  loginBackground,
  loginSlide1,
  loginSlide2,
  loginSlide3,
} from "../assets";
import LoginSlide from "../components/login/LoginSlide";
import { post } from "../lib/Api";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Toast from "../components/Toast/Toast";

const CandidateLogin = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const slides = [
    {
      image: loginSlide1,
      heading: "Secure Login",
      description: "Login confidently with our advanced encryption security.",
      loadingDivCount: 3,
      activeCircleIndex: 1,
    },
    {
      image: loginSlide2,
      heading: "Unlock Chakrra with a Tap",
      description:
        "Enjoy seamless login for Chakrra: Password-Free, Simplified Security",
      loadingDivCount: 3,
      activeCircleIndex: 2,
    },
    {
      image: loginSlide3,
      heading: "Seamless Access, Securely",
      description: "Simplify access without risking your security.",
      loadingDivCount: 3,
      activeCircleIndex: 3,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const backgroundImageStyle = {
    backgroundImage: `url(${loginBackground})`,
  };

  const handleChange = (e) => {
    const value = e.target.value;  
    const numericValue = value.replace(/\D/g, ''); 
    if (numericValue.length <= 10) {
      setPhoneNumber(numericValue);
    }
  };

  const onLogin = async () => {
    const data = { username: phoneNumber };
    setLoading(true);
    try {
      if (phoneNumber.length !== 10) {
        setLoading(false);
        Toast({ message: "Please enter a valid phone number", type: "error" });
        return
      }
      const res = await post(`user/candidate-login-send-otp`, data, {}, false);
      if (res?.status === true) {
        navigate("/candidate/login-otp", { state: { data } });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({ message: "Network Error", type: "error" });
      console.log("Error: ", error);
    }
  };

  return (
    <div className="background-image" style={backgroundImageStyle}>
      <div className="container">
        <div className="left-panel">
          <div>
            <img src={chakkraLogo} alt="Company Logo" className="logo" />
            <div className="powered-by">Powered by Adhaan Solution</div>
          </div>
          <div className="heading">Candidate Login</div>
          <div className="input-group">
            <label>Phone</label>
            <div className="input-with-icon">
              <input
                type="tel"
                className="input"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={handleChange}
                maxLength={10}
              />
              <i className="fa fa-phone" aria-hidden="true"></i>
            </div>
          </div>
          <div>
            <button className="button" onClick={onLogin} disabled={loading}>
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>
          </div>
          <Link to="/candidate/register" style={{ textDecoration: "none" }}>
            <div className="forgot-password">
              <span>Don't have an Account? Create One </span>
            </div>
          </Link>
          {/* <div className="contact-admin">
            Having issues with your login? <span>Contact Admin</span>
          </div> */}
        </div>
        <LoginSlide {...slides[currentSlide]} />

        <ToastContainer />
      </div>
    </div>
  );
};

export default CandidateLogin;
