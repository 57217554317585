import React, { useEffect, useState } from "react";
// import "../styles/Login.css";
import "../styles/ForgotPasswordOTP.css";
import {
  chakkraLogo,
  loginBackground,
  loginSlide1,
  loginSlide2,
  loginSlide3,
} from "../assets";
import LoginSlide from "../components/login/LoginSlide";
import { post } from "../lib/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Toast from "../components/Toast/Toast";
import { Button } from "antd";
import { InputOTP } from "antd-input-otp";

const CandidateLoginOTP = () => {
  const location = useLocation();
  const { data } = location.state;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const navigate = useNavigate();
  const slides = [
    {
      image: loginSlide1,
      heading: "Secure Login",
      description: "Login confidently with our advanced encryption security.",
      loadingDivCount: 3,
      activeCircleIndex: 1,
    },
    {
      image: loginSlide2,
      heading: "Unlock Chakrra with a Tap",
      description:
        "Enjoy seamless login for Chakrra: Password-Free, Simplified Security",
      loadingDivCount: 3,
      activeCircleIndex: 2,
    },
    {
      image: loginSlide3,
      heading: "Seamless Access, Securely",
      description: "Simplify access without risking your security.",
      loadingDivCount: 3,
      activeCircleIndex: 3,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  const backgroundImageStyle = {
    backgroundImage: `url(${loginBackground})`,
  };

  const onVerifyOTP = async () => {
    setLoading(true);
    try {
      const hasBlank = otp && otp.length > 5 && !(otp.some((item) => item === "")) ? false : true;

      if (hasBlank) {
        Toast({ message: "Please enter a valid OTP", type: "error" });
        setLoading(false);
        return;
      }
      const payload = { username: data.username, otp: otp?.join("") };
      const res = await post(`user/candidate-otp-verify`, payload, {}, false);
      if (res?.status === true) {
        Toast({ message: res?.message, type: "success" });

        setTimeout(() => {
            localStorage.setItem("__user_token__", res.data.token);
            localStorage.setItem("__user_type__", res.data.user_type);
            localStorage.setItem("__customer_name__", res.data.customer_name);
            localStorage.setItem("__user_name__", res.data.name);
    
            Toast({ message: "You are successfully loged in", type: "success" });
    
            navigate("/candidate/jobs");
        }, 1000);
        // setLoading(false);
      } else {
        Toast({ message: res?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error: ", error);
    }
  };

  const onResendOTP = async () => {
    setLoading(true);
    try {
      const payload = { username: data.username };
      const res = await post(`user/candidate-login-send-otp`, payload, {}, false);
      if (res?.status === true) {
        Toast({ message: "OTP resent successfully", type: "success" });
        setOtp("");
        setResendTimer(60);
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error: ", error);
    }
  };
  
  const handleOtpChange = (value) => {
    setOtp(value);
  };

  return (
    <div className="background-image" style={backgroundImageStyle}>
      <div className="container">
        <div className="left-panel">
          <div>
            <img src={chakkraLogo} alt="Company Logo" className="logo" />
            <div className="powered-by">Powered by Adhaan Solution</div>
          </div>
          <div className="heading">Enter OTP</div>
          <div className="input-group">
            <label>OTP</label>
            <div className="input-with-icon">
              <InputOTP
                inputType="numeric"
                onChange={handleOtpChange}
                value={otp}
                style={{ width: "100%", maxWidth: "300px", fontSize: "16px" }}
              />
            </div>
          </div>
          <div>
            <button className="button" onClick={onVerifyOTP} disabled={loading}>
              {loading ? "Verifying..." : "Verify OTP"}
            </button>
          </div>
          <div className="resend-otp">
            {resendTimer > 0 ? (
              <span>Resend OTP in {resendTimer} seconds</span>
            ) : (
              <Button
                type="link"
                block
                onClick={onResendOTP}
                disabled={loading}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  paddingLeft: "0px",
                }}
              >
                <div className="forgot-password">
                  <span>{loading ? "Resending OTP..." : "Resend OTP"}</span>
                </div>
              </Button>
            )}
          </div>
          {/* <Link to="/login" style={{ textDecoration: "none" }}>
            <div className="forgot-password">
              <span>Signin?</span>
            </div>
          </Link> */}
        </div>
        <LoginSlide {...slides[currentSlide]} />

        <ToastContainer />
      </div>
    </div>
  );
};

export default CandidateLoginOTP;
