/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  coinIcon,
  createRecruiterIcon,
  editIcon,
  exportIcon,
  importIcon,
  mailCheckIcon,
  mailIcon,
  phoneIcon,
  profileUserIcon,
  rightUserIcon,
  tableDeleteIcon,
  tableEditIcon,
  twoUserIcon,
  userRoundXIcon,
} from "../assets";
import { Button, DatePicker, Form, Input, Modal, Select, Table } from "antd";
import "../styles/RecruitersPage.css";
import { get, post, put } from "../lib/Api";
import { ToastContainer } from "react-toastify";
import Toast from "../components/Toast/Toast";
import { SearchOutlined } from "@ant-design/icons";
import StatusBadge from "../components/table/StatusBadge";
import ImportFileModal from "../components/modal/ImportFileModal ";
import ConfirmationModal from "../components/modal/ConfirmationModal";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

dayjs.extend(customParseFormat);

const RecruitersPage = () => {
  const dateFormat = "DD/MM/YYYY";
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const zones = [
    { value: "North", label: "North" },
    { value: "East", label: "East" },
    { value: "South", label: "South" },
    { value: "West", label: "West" },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [profileData, setProfileData] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isCreditModalOpen, setIsCreditModalOpen] = useState(false);
  const [creditCount, setCreditCount] = useState("");
  const [file, setFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [form] = Form.useForm();

  const [recruiterFormData, setRecruiterFormData] = useState({
    recruiterCode: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    state: "",
    zone: "",
    dateOfStarting: null,
    dateOfLeaving: null,
  });

  const [recruiterCount, setRecruiterCount] = useState({
    totalRecruiters: "",
    activeRecruiters: "",
    inactiveRecruiters: "",
    invitedRecruiters: "",
  });

  const [recruiterExportData, setRecruiterExportData] = useState({
    state: "",
    zone: "",
    startDate: null,
    endDate: null,
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "phoneNumber") {
      if (/^[0-9]*$/.test(value) || value === "") {
        setRecruiterFormData({
          ...recruiterFormData,
          [id]: value,
        });
      }
    } else {
      setRecruiterFormData({
        ...recruiterFormData,
        [id]: value,
      });
    }
  };

  const handleStateChange = (value, option) => {
    setRecruiterFormData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleDateChange = (date, id) => {
    setRecruiterFormData({
      ...recruiterFormData,
      [id]: date,
    });
  };

  const handleExportStateChange = (value, option) => {
    setRecruiterExportData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleExportDateChange = (date, id) => {
    setRecruiterExportData({
      ...recruiterExportData,
      [id]: date,
    });
  };

  const handleCreditCount = (e) => {
    const { value } = e.target;

    if (/^[0-9]*$/.test(value) || value === "") {
      setCreditCount(value);
    }
  };

  const [open, setOpen] = useState(false);
  const showCreateRecruiterModal = () => {
    setOpen(true);
  };

  const [profileOpen, setProfileOpen] = useState(false);
  const showProfileModal = () => {
    setProfileOpen(true);
  };

  const openImportModal = () => {
    setIsImportModalOpen(true);
  };

  const handleImportCancel = () => {
    setIsImportModalOpen(false);
    setFile(null);
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
    },
  });

  const columns = [
    {
      title: "Name & Email",
      sorter: true,
      key: "name",
      render: (result) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div><img src={profileUserIcon} alt="User Icon"/></div> */}
          <div
            style={{ paddingLeft: "10px", cursor: "pointer" }}
            onClick={() => {
              showProfileModal();
              setProfileData(result);
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#00adf0";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "black";
            }}
          >
            <div
              style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
            >{`${result.name}`}</div>
            <div
              style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}
            >
              {result.email}
            </div>
          </div>
        </div>
      ),
      width: "25%",
    },
    {
      title: "Recruiter Code",
      dataIndex: "user_code",
      width: "13%",
      align: "center",
      key: "recruiter_code",
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
      width: "15%",
      align: "center",
      key: "phone_number",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      width: "10%",
      align: "center",
      key: "zone",
    },
    {
      title: "State",
      dataIndex: "state",
      width: "10%",
      align: "center",
      key: "state",
    },
    {
      title: "Used Credit",
      dataIndex: "used_credits",
      width: "10%",
      align: "center",
      key: "used_credits",
    },
    {
      title: "Available Credit",
      dataIndex: "credits",
      width: "10%",
      align: "center",
      key: "credits",
    },
    {
      title: "Date of Joining",
      dataIndex: "start_date",
      width: "15%",
      align: "center",
      key: "start_date",
      sorter: true,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      width: "15%",
      align: "center",
      key: "last_login",
      sorter: true,
      render: (result) => (
        <>{result ? dayjs(result).format("DD-MM-YYYY") : "N/A"}</>
      ),
    },
    {
      title: "Status",
      align: "center",
      key: "is_active",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          title="Change the Status"
          onClick={() => {
            setIsStatusModalOpen(true);
            setProfileData(result);
          }}
        >
          <StatusBadge
            status={result.is_active === true ? "Active" : "Inactive"}
          />
          <div></div>
        </div>
      ),
      width: "10%",
    },
    {
      title: "",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            title="Edit Recruiter"
            onClick={() => {
              setProfileData(result);
              setRecruiterFormData({
                recruiterCode: result?.user_code,
                firstName: result?.first_name,
                lastName: result?.last_name,
                email: result?.email,
                phoneNumber: result?.phone_number,
                state: result?.state_id,
                zone: result?.zone,
                dateOfStarting: result?.start_date
                  ? dayjs(result.start_date, dateFormat)
                  : null,
                dateOfLeaving: result?.end_date
                  ? dayjs(result?.end_date, dateFormat)
                  : null,
              });
              showCreateRecruiterModal();
              setProfileOpen(false);
              setEditModalVisible(true);
            }}
          >
            <img src={tableEditIcon} alt="Edit" />
          </div>
          <div style={{ cursor: "pointer" }} title="Delete Recruiter">
            <img
              src={tableDeleteIcon}
              alt="Delete"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setProfileData({ ...profileData, id: result.id });
              }}
            />
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const getRandomuserParams = (params) => ({
    page_no: params.pagination?.current,
    page_size: params.pagination?.pageSize,
    query: debouncedValue,
    status: selectedStatus,
    sort_order: params.sortOrder,
  });

  const fetchData = async () => {
    setLoading(true);
    const res = await get(
      `user/recruiter-list`,
      getRandomuserParams(tableParams),
      {},
      true
    );
    if (res?.status === true) {
      setData(res.user_list.results);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.count,
        },
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchRecruiterCount = async () => {
    setLoading(true);
    const res = await get(`user/recruiters-count`, null, {}, true);
    if (res?.status === true) {
      setRecruiterCount({
        totalRecruiters: res.data.total,
        activeRecruiters: res.data.active,
        inactiveRecruiters: res.data.inactive,
        invitedRecruiters: res.data.invited,
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    setLoading(true);
    const res = await get(`master/state-list`, { country_id: 1 }, {}, true);
    if (res?.status === true) {
      setStates(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    fetchData();
    fetchRecruiterCount();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    debouncedValue,
    selectedStatus,
  ]);

  useEffect(() => {
    fetchStates();
  }, []);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: Array.isArray(sorter)
        ? undefined
        : `${sorter.columnKey}: ${sorter.order}`,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSaveRecruiter = async () => {
    setLoading(true);
    try {
      if (
        !recruiterFormData.firstName ||
        !recruiterFormData.email ||
        !recruiterFormData.phoneNumber ||
        !recruiterFormData.recruiterCode ||
        !recruiterFormData.state ||
        !recruiterFormData.zone ||
        !recruiterFormData.dateOfStarting
      ) {
        Toast({ message: "Please fill all required files", type: "error" });
        setLoading(false);
        return;
      }
      let res;
      if (editModalVisible) {
        // Update recruiter
        const data = {
          ...recruiterFormData,
          dateOfStarting: recruiterFormData.dateOfStarting.format(dateFormat),
          dateOfLeaving: recruiterFormData?.dateOfLeaving
            ? recruiterFormData.dateOfLeaving.format(dateFormat)
            : null,
          id: profileData.id,
        };
        res = await put(`user/recruiter-update`, data, {}, true);
        if (res?.status === true) {
          Toast({ message: "Recruiter updated successfully", type: "success" });
        } else {
          Toast({ message: res?.message, type: "error" });
        }
      } else {
        const data = {
          ...recruiterFormData,
          dateOfStarting: recruiterFormData.dateOfStarting.format(dateFormat),
          dateOfLeaving: recruiterFormData?.dateOfLeaving
            ? recruiterFormData.dateOfLeaving.format(dateFormat)
            : null,
        };
        res = await post(`user/recruiter-create`, data, {}, true);
        if (res?.status === true) {
          Toast({ message: "Recruiter created successfully", type: "success" });
        } else {
          Toast({ message: res?.message, type: "error" });
        }
      }

      if (res?.status === true) {
        setRecruiterFormData({
          recruiterCode: "",
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          state: "",
          zone: "",
          dateOfStarting: null,
          dateOfLeaving: null,
        });
        fetchData();
        fetchRecruiterCount();
        setOpen(false);
        setEditModalVisible(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to ${
          editModalVisible ? "update" : "create"
        } recruiter`,
        type: "error",
      });
    }
  };

  const handleDeleteRecruiter = async () => {
    setLoading(true);
    try {
      let res;

      const data = { ...recruiterFormData, id: profileData.id };
      res = await put(`user/recruiter-delete`, data, {}, true);
      if (res?.status === true) {
        Toast({ message: "Recruiter Deleted successfully", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        fetchData();
        fetchRecruiterCount();
        setIsDeleteModalOpen(false);
        setProfileOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to delete recruiter`,
        type: "error",
      });
    }
  };

  const handleStatusRecruiter = async () => {
    setLoading(true);
    try {
      let res;

      const data = { id: profileData.id };
      res = await put(`user/recruiter-status`, data, {}, true);
      if (res?.status === true) {
        Toast({
          message: "Recruiter Status successfully changed",
          type: "success",
        });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        fetchData();
        fetchRecruiterCount();
        setIsStatusModalOpen(false);
        setProfileOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to change status of this recruiter`,
        type: "error",
      });
    }
  };

  const handleFileImport = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await post(
        `user/recruiter-bulk-import`,
        formData,
        { "Content-Type": "multipart/form-data" },
        true
      );

      Toast({
        message: res?.message || "Imported successfully",
        type: "success",
      });
      fetchData();
      fetchRecruiterCount();
      setLoading(false);
      return res.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      Toast({
        message: error.response?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const handleExportData = async () => {
    setLoading(true);
    try {
      if (!recruiterExportData.startDate) {
        Toast({ message: "Please fill all required files", type: "error" });
        setLoading(false);
        return;
      }
      let res;
      const data = {
        ...recruiterExportData,
        startDate: recruiterExportData.startDate.format(dateFormat),
        endDate: recruiterExportData.endDate
          ? recruiterExportData.endDate.format(dateFormat)
          : null,
      };
      res = await post(`user/recruiter-bulk-export`, data, {}, true);
      if (res?.status === true) {
        setRecruiterExportData({
          state: "",
          zone: "",
          startDate: null,
          endDate: null,
        });
        const element = document.createElement("a");
        element.href = baseUrl + res.file_path;
        element.download = "";
        element.click();
        setIsExportModalOpen(false);
        Toast({ message: "Report successfully downloaded", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
      Toast({
        message: "Network Error",
        type: "error",
      });
    }
  };

  const handleAddCreditData = async () => {
    setLoading(true);
    try {
      let res;
      const data = {
        user_id: profileData?.id,
        credits: creditCount,
      };
      res = await post(`user/credits-add`, data, {}, true);
      if (res?.status === true) {
        setCreditCount("");
        setIsCreditModalOpen(false);
        fetchData(setProfileOpen(false));
        Toast({ message: "Credit successfully added", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
      Toast({
        message: "Network Error",
        type: "error",
      });
    }
  };

  const handleResetPasswordFinish = async (values) => {
    try {
      let res;

      const data = {
        id: profileData.id,
        new_password: values.confirm_password,
      };
      res = await put(`user/reset-password`, data, {}, true);
      if (res?.status === true) {
        Toast({ message: "Password successfully changed", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        form.resetFields();
        setIsResetPasswordModalOpen(false);
        setProfileOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to update password`,
        type: "error",
      });
    }
  };

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="header-title">Recruiters</div>
          <div className="header-subtitle">
            Onboard new recruiters by assigning them specific roles and
            permissions.
          </div>
        </div>
        <div className="button-group">
          <div>
            <button
              className="secondary-button"
              title="Export Recruiter's Data"
              onClick={() => {
                setIsExportModalOpen(true);
              }}
            >
              <img src={exportIcon} alt="exportIcon" />
              Export
            </button>
          </div>
          <div>
            <button
              className="secondary-button"
              title="Import bulk Recruiters"
              onClick={openImportModal}
            >
              <img src={importIcon} alt="importIcon" />
              Import
            </button>
          </div>
          <div>
            <button
              className="primary-button"
              onClick={() => {
                showCreateRecruiterModal();
                setEditModalVisible(false);
                setRecruiterFormData({
                  recruiterCode: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  state: "",
                  zone: "",
                  dateOfStarting: "",
                  dateOfLeaving: "",
                });
              }}
            >
              <img src={createRecruiterIcon} alt="createRecruiterIcon" />
              Create Recruiter
            </button>
          </div>
        </div>
      </div>

      <div className="stats-cards">
        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Recruiters</div>
            <div className="value">{recruiterCount.totalRecruiters}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={rightUserIcon} alt="rightUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Active Recruiters</div>
            <div className="value">{recruiterCount.activeRecruiters}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={userRoundXIcon} alt="userRoundXIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Inactive Recruiters</div>
            <div className="value">{recruiterCount.inactiveRecruiters}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={mailCheckIcon} alt="mailCheckIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Invited</div>
            <div className="value">{recruiterCount.invitedRecruiters}</div>
          </div>
        </div>
      </div>

      <div className="filter-section">
        <div className="filter-bar">
          <div className="filter-group">
            <div>
              <Input
                placeholder="Search by Name, Email, PhoneNo or Recruiter Code"
                className="search-input"
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "20px",
                    }}
                  />
                }
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div>
              <Select
                defaultValue="all"
                className="filter-select"
                onChange={handleStatusChange}
                options={[
                  { value: "all", label: "All" },
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
              />
            </div>
          </div>
          {/* <div>
            <button className="filter-button">
              <img src={filterIcon} alt="Filter Icon" /> <span>Filter</span>
            </button>
          </div> */}
        </div>
        <div className="table-container">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <Modal
        open={open}
        width={760}
        maskClosable={false}
        title={editModalVisible ? "Update Recruiter" : "Create Recruiter"}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className="create-modal-cancel-button"
              onClick={() => {
                setOpen(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="create-modal-confirm-button"
              onClick={handleSaveRecruiter}
              disabled={loading}
            >
              {" "}
              {editModalVisible
                ? loading
                  ? "Updating..."
                  : "Update"
                : loading
                ? "Creating..."
                : "Create"}
            </Button>
          </>
        )}
      >
        <hr
          style={{ backgroundColor: "#ced7de", height: "1px", border: "none" }}
        />

        <div className="recruiter-create-raw">
          <div className="recruiter-create-input-div">
            <label htmlFor="recruiterCode">
              Recruiter Code <span className="text-danger">*</span>
            </label>
            <Input
              id="recruiterCode"
              placeholder="Enter Code"
              maxLength="10"
              className="recruiter-create-input"
              value={recruiterFormData.recruiterCode}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="recruiter-create-raw">
          <div className="recruiter-create-input-div">
            <label htmlFor="firstName">
              First name <span className="text-danger">*</span>
            </label>
            <Input
              id="firstName"
              placeholder="Enter first name"
              className="recruiter-create-input"
              value={recruiterFormData.firstName}
              onChange={handleInputChange}
            />
          </div>
          <div className="recruiter-create-input-div">
            <label htmlFor="lastName">Last name</label>
            <Input
              id="lastName"
              placeholder="Enter last name"
              className="recruiter-create-input"
              value={recruiterFormData.lastName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="email">
              Email address <span className="text-danger">*</span>
            </label>
            <Input
              id="email"
              placeholder="Enter email address"
              className="recruiter-create-input"
              value={recruiterFormData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="phoneNumber">
              Phone Number <span className="text-danger">*</span>
            </label>
            <Input
              id="phoneNumber"
              placeholder="Enter phone number"
              maxLength="10"
              className="recruiter-create-input"
              value={recruiterFormData.phoneNumber}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="state">
              State <span className="text-danger">*</span>
            </label>
            <Select
              id="state"
              placeholder="Select a state"
              className="job-create-select-input"
              onChange={handleStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={states.map((state) => ({
                value: state.id,
                label: state.name,
                id: "state",
              }))}
              value={recruiterFormData.state || undefined}
            />
          </div>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="zone">
              Zone <span className="text-danger">*</span>
            </label>
            <Select
              id="zone"
              placeholder="Select a Zone"
              className="job-create-select-input"
              onChange={handleStateChange}
              options={zones.map((zone) => ({
                value: zone.value,
                label: zone.label,
                id: "zone",
              }))}
              value={recruiterFormData.zone || undefined}
            />
          </div>
        </div>

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="dateOfStarting">
              Date of starting<span className="text-danger">*</span>
            </label>
            <DatePicker
              id="dateOfStarting"
              disabledDate={(current) =>
                current && current.isAfter(dayjs().endOf("day"))
              }
              className="job-create-date-input"
              format={dateFormat}
              value={recruiterFormData.dateOfStarting}
              onChange={(date) => handleDateChange(date, "dateOfStarting")}
            />
          </div>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="dateOfLeaving">Date of leaving</label>
            <DatePicker
              id="dateOfLeaving"
              disabledDate={(current) => {
                return current < recruiterFormData.dateOfStarting;
              }}
              className="job-create-date-input"
              format={dateFormat}
              value={recruiterFormData.dateOfLeaving}
              onChange={(date) => handleDateChange(date, "dateOfLeaving")}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={profileOpen}
        width={486}
        // height={1000}
        className="ant-modal-content-modify recruiter-modal"
        style={{
          position: "fixed",
          top: "-7px",
          right: "-7px",
          backgroundColor: "#FFFFFF",
          padding: 0,
          height: "100vh",
        }}
        title=" &nbsp; &nbsp;  View Recruiter Details"
        onOk={() => {
          setProfileOpen(false);
        }}
        onCancel={() => {
          setProfileOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
      >
        {/* <hr /> */}
        <div className="profile-main-details">
          <div style={{ flex: 2 }}>
            <img src={profileUserIcon} alt="User" width="80px" height="80px" />
          </div>
          <div style={{ flex: 7 }}>
            <div style={{ display: "flex", gap: "6px" }}>
              <div className="profile-name-text">{profileData?.name} </div>
              {profileData?.is_active === true ? (
                <div
                  className="active-lable"
                  onClick={() => {
                    setIsStatusModalOpen(true);
                  }}
                >
                  Active
                </div>
              ) : (
                <div
                  className="inactive-lable"
                  onClick={() => {
                    setIsStatusModalOpen(true);
                  }}
                >
                  Inactive
                </div>
              )}
            </div>
            <div className="profile-content-div">
              {" "}
              <div className="profile-icon">
                <img src={mailIcon} alt="Mail Icon" />
              </div>{" "}
              <div className="profile-content-">{profileData?.email}</div>
            </div>
            <div className="profile-content-div">
              {" "}
              <div className="profile-icon">
                <img src={phoneIcon} alt="Phone Icon" />
              </div>{" "}
              <div className="profile-content-">
                {profileData?.phone_number}
              </div>
            </div>
          </div>
          <div
            style={{ flex: 1, cursor: "pointer" }}
            onClick={() => setIsCreditModalOpen(true)}
          >
            <div>
              <img src={coinIcon} alt="Coin Icon" />
            </div>
            <div style={{ textAlign: "center", marginTop: "-5px" }}>
              {profileData?.credits}
            </div>
          </div>
        </div>
        <div className="profile-details-main">
          <div>
            {" "}
            <div className="profile-details-div">
              Recruiter Details{" "}
              <button
                className="recruiter-edit-button"
                onClick={() => {
                  setRecruiterFormData({
                    recruiterCode: profileData?.user_code,
                    firstName: profileData?.first_name,
                    lastName: profileData?.last_name,
                    email: profileData?.email,
                    phoneNumber: profileData?.phone_number,
                    state: profileData?.state_id,
                    zone: profileData?.zone,
                    dateOfStarting: profileData?.start_date
                      ? dayjs(profileData.start_date, dateFormat)
                      : null,
                    dateOfLeaving: profileData?.end_date
                      ? dayjs(profileData?.end_date, dateFormat)
                      : null,
                  });
                  showCreateRecruiterModal();
                  setProfileOpen(false);
                  setEditModalVisible(true);
                }}
              >
                <img src={editIcon} alt="Edit Icon" /> Edit
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 20px",
                gap: "4px",
              }}
            >
              <div>
                <div className="details-role">Recruiter Code</div>
                <div className="details-role">First Name</div>
                <div className="details-role">Last Name</div>
                <div className="details-role">Email</div>
                <div className="details-role">Phone Number</div>
                <div className="details-role">State</div>
                <div className="details-role">Zone</div>
                <div className="details-role">Created at</div>
                <div className="details-role">Date Of Starting</div>
                <div className="details-role">Date Of Leaving</div>
              </div>
              <div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.user_code || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.first_name || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.last_name || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.email || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.phone_number || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.state || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.zone || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp;{" "}
                  {profileData?.created_at
                    ? dayjs(profileData?.created_at).format("DD-MM-YYYY")
                    : "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.start_date || "N/A"}
                </div>
                <div className="details-role-name">
                  : &nbsp; {profileData?.end_date || "N/A"}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div>
            <div className="profile-footer">
              <Button
                className="recruiter-reset-password-button"
                onClick={() => {
                  setIsResetPasswordModalOpen(true);
                }}
              >
                {" "}
                Reset password{" "}
              </Button>
              <Button
                className="recruiter-delete-button"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                {" "}
                Delete recruiter{" "}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <ImportFileModal
        isVisible={isImportModalOpen}
        onClose={handleImportCancel}
        onImport={handleFileImport}
        downloadSampleSheetUrl="user/recruiter-sample-sheet"
        title="Import Excel File"
      />

      <Modal
        open={isExportModalOpen}
        width={760}
        maskClosable={false}
        title="Export Recruiter Data"
        onOk={() => {
          setIsExportModalOpen(false);
        }}
        onCancel={() => {
          setIsExportModalOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className="create-modal-cancel-button"
              onClick={() => {
                setIsExportModalOpen(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="create-modal-confirm-button"
              onClick={handleExportData}
              disabled={loading}
            >
              {loading ? "Exporting..." : "Export"}
            </Button>
          </>
        )}
      >
        <hr
          style={{ backgroundColor: "#ced7de", height: "1px", border: "none" }}
        />

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="startDate">
              Date of starting <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="startDate"
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              className="job-create-date-input"
              format={dateFormat}
              onChange={(date) => handleExportDateChange(date, "startDate")}
              value={recruiterExportData.startDate}
            />
          </div>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="endDate">Date of leaving</label>
            <DatePicker
              id="endDate"
              disabledDate={(current) => {
                return current < recruiterFormData.startDate;
              }}
              className="job-create-date-input"
              format={dateFormat}
              value={recruiterExportData.endDate}
              onChange={(date) => handleExportDateChange(date, "endDate")}
            />
          </div>
        </div>

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="state">State</label>
            <Select
              id="state"
              placeholder="Select a state"
              className="job-create-select-input"
              onChange={handleExportStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={states.map((state) => ({
                value: state.id,
                label: state.name,
                id: "state",
              }))}
              value={recruiterExportData.state || undefined}
            />
          </div>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="zone">Zone</label>
            <Select
              id="zone"
              placeholder="Select a Zone"
              className="job-create-select-input"
              onChange={handleExportStateChange}
              options={zones.map((zone) => ({
                value: zone.value,
                label: zone.label,
                id: "zone",
              }))}
              value={recruiterExportData.zone || undefined}
            />
          </div>
        </div>
      </Modal>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onConfirm={handleDeleteRecruiter}
        onCancel={() => setIsDeleteModalOpen(false)}
        loading={loading}
        title="Confirm Delete"
        message="Are you sure you want to delete this recruiter?"
      />

      <Modal
        title="Reset Password"
        centered
        open={isResetPasswordModalOpen}
        onCancel={() => {
          setIsResetPasswordModalOpen(false);
        }}
        footer={[
          <Button
            key="cancel"
            className="create-modal-cancel-button"
            onClick={() => {
              setIsResetPasswordModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="create-modal-confirm-button"
            onClick={() => form.submit()}
          >
            Confirm
          </Button>,
        ]}
        width={500}
      >
        <Form
          form={form}
          name="reset_password"
          initialValues={{ remember: true }}
          onFinish={handleResetPasswordFinish}
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: "Please input your new password!" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>

      <ConfirmationModal
        isOpen={isStatusModalOpen}
        onConfirm={handleStatusRecruiter}
        onCancel={() => setIsStatusModalOpen(false)}
        loading={loading}
        title="Confirm Status Change"
        // message="Are you sure you want to change status this recruiter?"
        message={`Are you sure you want to change ${
          profileData?.name
        }'s status to ${
          profileData.is_active === true ? "inactive" : "active"
        }?`}
      />

      <Modal
        open={isCreditModalOpen}
        width={560}
        maskClosable={false}
        title="Adding Credits"
        onOk={() => {
          setIsCreditModalOpen(false);
        }}
        onCancel={() => {
          setIsCreditModalOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className="create-modal-cancel-button"
              onClick={() => {
                setIsCreditModalOpen(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="create-modal-confirm-button"
              onClick={handleAddCreditData}
              disabled={loading || !creditCount}
            >
              {loading ? "Adding..." : "Add"}
            </Button>
          </>
        )}
      >
        <hr
          style={{ backgroundColor: "#ced7de", height: "1px", border: "none" }}
        />

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div
            className="recruiter-create-input-div"
            style={{ display: "flex", width: "100%" }}
          >
            {" "}
            <label
              htmlFor="creditCount"
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Credits <span className="text-danger">*</span>
            </label>
            <Input
              id="creditCount"
              placeholder="Enter Credits"
              maxLength="10"
              className="recruiter-create-input"
              value={creditCount}
              onChange={handleCreditCount}
              style={{ flex: 4 }}
            />
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default RecruitersPage;
